import backend from "../../config/axios"
import { takeEvery, call, put } from "redux-saga/effects"
import { IStatus, 
         fetch, 
         fetchSucceded, 
         fetchFailed} from "../../reducers/statusesSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runfetchObservationStatus(action: PayloadAction<IStatus>) {
    try {
        const statuses: Array<IStatus>  = yield call(() => backend.get(`observations/statuses`));
        yield put(fetchSucceded(statuses));
    } catch (e) {
        yield put(fetchFailed(e));
    }
}

function* statusesFetchSaga() {
    yield takeEvery(fetch.type, runfetchObservationStatus)
}

export default statusesFetchSaga