import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { INotificationMessage } from "../services/notification"

export interface ISystem {
    id?: number
    name?: string
}

export interface ISystemsState {
    items: Array<ISystem> | undefined,
    isLoading: boolean,
    message: INotificationMessage | undefined,
    isError: boolean
}

const initialState: ISystemsState = {
    items: undefined,
    isLoading: false,
    message: undefined,
    isError: false
}

const systemsSlice = createSlice({
    name: "system",
    initialState,
    reducers: {
        
        fetch: (state: ISystemsState) => {
            state.message = undefined;
            state.isLoading = true;
            state.isError = false;
        },
        fetchSucceded: (state: ISystemsState, action: PayloadAction<Array<ISystem>>) => {
            state.items = action.payload;
            state.isLoading = false;
            state.message = undefined;
            state.isError = false;
        },
        fetchFailed: (state: ISystemsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.message = { message: 'Error on getting systems', variant: "error" };
            state.isError = true;
        },
        add: (state: ISystemsState, action: PayloadAction<ISystem>) => {
            state.isError = false;
            state.isLoading = true;
        },
        addSucceded: (state: ISystemsState, action: PayloadAction<ISystem>) => {  
            state.isLoading = false;
            state.items = [action.payload].concat(state.items ?? []).sort(function (a, b) { return (a.id ?? 0) - (b.id ?? 0) });
            state.isError = false;
        },
        addFailed: (state: ISystemsState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.isError = true;
            state.message = {message: 'Error on adding new system', variant: 'error'}
        },
        update: (state: ISystemsState, action: PayloadAction<ISystem>) => {
            state.isError = false;
        },
        updateSucceded: (state: ISystemsState, action: PayloadAction<ISystem>) => {  
            state.isLoading = false;
            if(state.items) {
                let updateIndex = state.items?.findIndex(x => x.id === action.payload.id);
                if(updateIndex >= 0) {
                    state.items[updateIndex] = action.payload;
                }
            }

            state.isError = false;
        },
        updateFailed: (state: ISystemsState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.isError = true;
            state.message = {message: 'Failed to update system', variant: 'error'}
        },
        remove: (state: ISystemsState, action: PayloadAction<ISystem>) => {
            state.isError = false;
        },
        removeSucceded: (state: ISystemsState, action: PayloadAction<number>) => {  
            state.isLoading = false;
            if(state.items) {
                state.items.splice(state.items.findIndex(x => x.id === action.payload), 1);
            }
            state.isError = false;
        },
        removeFailed: (state: ISystemsState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.isError = true;
            state.message = {message: 'Failed to update system', variant: 'error'}
        },
    },
})

export const { 
    fetch,
    fetchSucceded,
    fetchFailed,
    add, 
    addSucceded, 
    addFailed,
    update, 
    updateSucceded, 
    updateFailed,
    remove, 
    removeSucceded, 
    removeFailed, 
 } = systemsSlice.actions

export default systemsSlice