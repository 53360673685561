import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { addReview, addReviewSucceded, addReviewFailed } from "../../reducers/reviewsSlice"
import { IReview } from "../../reducers/reviewsSlice"
import navigateTo from '../../services/navigation'
import { PayloadAction } from "@reduxjs/toolkit"


function* runCreateReview(action: PayloadAction<IReview>) {
  try {
    const newReview = yield call((data: IReview) =>
       backend.post(`reviews`, data), action.payload
    )
    
    yield put(addReviewSucceded(newReview));
    navigateTo('/review/' + newReview.id);

  } catch (e) {
    yield put(addReviewFailed(e));
  }
}

function* createReviewSaga() {
  yield takeEvery(addReview.type, runCreateReview)
}

export default createReviewSaga