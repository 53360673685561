import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { INotificationMessage } from "../services/notification"

export interface IReview {
    id?: number,
    siteId: number,
    description?: string
    modifiedBy?: string
    modifiedTimestamp?: Date
    department?: string
    status?: string
    isRFT?: boolean
    code?: string
    lot?: string
    bulkLot?: string
    mfgStartDate: Date
    dateReceivedByQa: Date
    dateRviewQaStart: Date
    reviewerProd: string
    reviewerQa: string
    dateCommentsSent: Date
    dateAnswersReceived: Date
    dateApporved: Date
    comments: string
    archived?: boolean
    numberOfObservations: number
}

export interface IReviewPatch {
    id: number,
    patch: Array<any>
}

export interface IReviewsState {
    items: Array<IReview> | undefined,
    editItem: IReview | undefined,
    isLoading: boolean,
    isError: boolean
    message: INotificationMessage | undefined,
}

const initialState: IReviewsState = {
    items: undefined,
    editItem: undefined,
    isLoading: false,
    isError: false,
    message: undefined,
}

const reviewSlice = createSlice({
    name: "review",
    initialState,
    reducers: {
        fetchReviews: (state: IReviewsState, action?: PayloadAction<any>) => {
            state.isError = false;
            state.isLoading = true;
        },
        fetchReviewsSucceeded: (state: IReviewsState, action: PayloadAction<Array<IReview>>) => {
            state.items = action.payload;
            state.isLoading = false;
            state.isError = false;
        },
        fetchReviewsFailed: (state: IReviewsState, action: PayloadAction<any>) => {
            state.message = { message: 'Error on fetching review list', variant: "error" };
            state.isError = true;
            state.isLoading = false;
        },
        fetchReview: (state: IReviewsState, action: PayloadAction<number>) => {
            state.isError = false;
            state.isLoading = true;
        },
        fetchReviewSucceeded: (state: IReviewsState, action: PayloadAction<IReview>) => {
            state.editItem = action.payload;
            state.isLoading = false;
            state.isError = false;
        },
        fetchReviewFailed: (state: IReviewsState, action: PayloadAction<any>) => {
            state.message = { message: 'Error on fetching review list', variant: "error" };
            state.isError = true;
            state.isLoading = false;
        },
        updateReview: (state: IReviewsState, action: PayloadAction<IReview>) => {
            state.message = undefined;
            state.isLoading = true;
        },
        updateReviewSucceded: (state: IReviewsState, action: PayloadAction<IReview>) => {
            state.isLoading = false;
            state.message = undefined;
            state.isError = false;

            const { id, ...rest } = action.payload

            if (state.items) {
                state.items = state.items.map(review => {
                    if (review.id === id) {
                        return { ...review, ...rest }
                    }

                    return review
                })
            }


        },
        updateReviewFailed: (state: IReviewsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.message = { message: 'Error on saving review changes', variant: "error" };
            state.isError = true;
        },
        addReview: (state: IReviewsState, action: PayloadAction<IReview>) => {
            state.isError = false;
            state.isLoading = true;
        },
        addReviewSucceded: (state: IReviewsState, action: PayloadAction<IReview>) => {
            state.isLoading = false;
            state.items = [action.payload].concat(state.items ?? []);
            state.isError = false;
        },
        addReviewFailed: (state: IReviewsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isError = true;
            state.message = { message: 'Error on adding review', variant: 'error' }
        },
        partialUpdateReview: (state: IReviewsState, action: PayloadAction<IReviewPatch>) => {
            state.message = undefined;
            state.isLoading = true;
        },
        partialUpdateReviewSucceded: (state: IReviewsState, action: PayloadAction<IReview>) => {
            state.isLoading = false;
            state.message = undefined;
            state.isError = false;

            state.editItem = action.payload;

            const { id, ...rest } = action.payload

            if (state.items) {
                state.items = state.items.map(review => {
                    if (review.id === id) {
                        return { ...review, ...rest }
                    }
                    return review
                })
            }
        },
        partialUpdateReviewFailed: (state: IReviewsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.message = { message: 'Error on saving review changes', variant: "error" };
            state.isError = true;
        },
        resetMessage: (state: IReviewsState) => {
            state.message = undefined;
            state.isError = false;
        },
        updateObservationCount: (state: IReviewsState, action: PayloadAction<any>) => {
            if (state.items) {
                let itemToUpdate = state.items.find(item => item.id === action.payload.id);
                if (itemToUpdate) {
                    itemToUpdate.numberOfObservations = action.payload.numberOfObservations;
                }
            }
        },
        deleteReview: (state: IReviewsState, action: PayloadAction<number>) => {
            state.message = undefined;
            state.isLoading = true;
            state.isError = false;
        },
        deleteReviewSucceded: (state: IReviewsState, action: PayloadAction<number>) => {
            if (state.items && action.payload) {
                state.items.splice(state.items.findIndex(x => x.id === action.payload), 1);
            }

            state.isLoading = false;
            state.message = undefined;
            state.isError = false;
        },
        deleteReviewFailed: (state: IReviewsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.message = { message: 'Error on deleting review', variant: "error" };
            state.isError = true;
        },
        archiveReview: (state: IReviewsState, action: PayloadAction<number>) => {
            state.message = undefined;
            state.isLoading = true;
            state.isError = false;
        },
        archiveReviewSucceded: (state: IReviewsState, action: PayloadAction<IReview>) => {
            if (state.items && action.payload) {
                state.items.splice(state.items.findIndex(x => x.id === action.payload.id), 1);
            }

            state.isLoading = false;
            state.message = undefined;
            state.isError = false;
        },
        archiveReviewFailed: (state: IReviewsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.message = { message: 'Error on archiving reviews', variant: "error" };
            state.isError = true;
        },
        unarchiveReview: (state: IReviewsState, action: PayloadAction<number>) => {
            state.message = undefined;
            state.isLoading = true;
            state.isError = false;
        },
        unarchiveReviewSucceded: (state: IReviewsState, action: PayloadAction<IReview>) => {
            if (state.items && action.payload) {
                state.items.splice(state.items.findIndex(x => x.id === action.payload.id), 1);
            }

            state.isLoading = false;
            state.message = undefined;
            state.isError = false;
        },
        unarchiveReviewFailed: (state: IReviewsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.message = { message: 'Error on unarchiving reviews', variant: "error" };
            state.isError = true;
        },
    },
})

export const {
    fetchReviews,
    fetchReviewsSucceeded,
    fetchReviewsFailed,
    fetchReview,
    fetchReviewSucceeded,
    fetchReviewFailed,
    addReview,
    addReviewSucceded,
    addReviewFailed,
    deleteReview,
    deleteReviewSucceded,
    deleteReviewFailed,
    updateReview,
    updateReviewSucceded,
    updateReviewFailed,
    partialUpdateReview,
    partialUpdateReviewSucceded,
    partialUpdateReviewFailed,
    resetMessage,
    updateObservationCount,
    archiveReview,
    archiveReviewSucceded,
    archiveReviewFailed,
    unarchiveReview,
    unarchiveReviewSucceded,
    unarchiveReviewFailed, } = reviewSlice.actions

export default reviewSlice