import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import store from "./store";
import history from "./services/browserHistory";
import App from './App';
import { Router } from "react-router-dom";
import { AuthProvider } from './auth/AuthProvider';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { unregister } from './registerServiceWorker';
import { setAutoFreeze } from 'immer';
import { SnackbarProvider } from 'notistack';

setAutoFreeze(false);

const theme = createMuiTheme({
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
});

ReactDOM.render(
    <Provider store={store}>
        <AuthProvider>
            <Router history={history}>
                <MuiThemeProvider theme={theme}>
                    <SnackbarProvider maxSnack={5} 
                                      anchorOrigin={{ 
                                          vertical: 'bottom', 
                                          horizontal: 'center', 
                                      }}
                    >
                        <App />
                    </SnackbarProvider>
                </MuiThemeProvider>
            </Router>
        </AuthProvider>
    </Provider>,
    document.getElementById('root')
);

//registerServiceWorker();
unregister();
