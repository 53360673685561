import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { update, updateSucceded, updateFailed, IObservation } from "../../reducers/observationsSlice"
import { PayloadAction } from "@reduxjs/toolkit"


function* runUpdateObservation(action: PayloadAction<IObservation>) {
  try {
    const newObservation: IObservation = yield call((data: IObservation) =>
       backend.put(`observations/` + data.id, data), action.payload
    );
    yield put(updateSucceded(newObservation));
  } catch (e) {
    yield put(updateFailed(e));
  }
}

function* observationUpdateSaga() {
  yield takeEvery(update.type, runUpdateObservation)
}

export default observationUpdateSaga