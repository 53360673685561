import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import {
  add,
  addSucceded,
  addFailed,
  IDepartment
} from "../../reducers/departmentsSlice"
import { PayloadAction } from "@reduxjs/toolkit"


function* runCreateDepartment(action: PayloadAction<IDepartment>) {
  try {
    const newDepartment: IDepartment = yield call((data: IDepartment) =>
      backend.post(`departments`, data), action.payload);

    yield put(addSucceded(newDepartment));
  } catch (e) {
    yield put(addFailed(e));
    console.error("Error while creating department", e);
  }
}

function* departmentsCreateSaga() {
  yield takeEvery(add.type, runCreateDepartment)
}

export default departmentsCreateSaga