import backend from "../../config/axios"
import { takeEvery, call, put } from "redux-saga/effects"
import { IReviewStatusesState, 
         fetch, 
         fetchSucceded, 
         fetchFailed,
         IReviewStatus} from "../../reducers/reviewStatusesSlice"
import { PayloadAction } from "@reduxjs/toolkit"


function* runFetchReviewStatus(action: PayloadAction<any>) {
    try {
        const reviewStatuses: Array<IReviewStatus> = yield call(() => backend.get(`reviewstatuses`));
        yield put(fetchSucceded(reviewStatuses));
    } catch (e) {
        yield put(fetchFailed(e));
    }
}

function* reviewStatusFetchSaga() {
    yield takeEvery(fetch.type, runFetchReviewStatus)
}

export default reviewStatusFetchSaga