import backend from "../../config/axios"
import { takeEvery, call, put } from "redux-saga/effects"
import { fetch, 
         fetchSucceded, 
         fetchFailed, 
         IObservation} from "../../reducers/observationsSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runFetchObservations(action: PayloadAction<number>) {
    try {
        const observations: Array<IObservation> = yield call(
            (id: number) => backend.get(`observations/` + id), action.payload     
        );
        yield put(fetchSucceded(observations));
    } catch (e) {
        yield put(fetchFailed(e));
    }
}

function* observationFetchSaga() {
    yield takeEvery(fetch.type, runFetchObservations)
}

export default observationFetchSaga