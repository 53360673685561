import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { 
    IReviewer, 
    update, 
    updateSucceded, 
    updateFailed } from "../../reducers/reviewersSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runUpdateReviewer(action: PayloadAction<IReviewer>) {
  try {
    const newStatus: IReviewer = yield call((data: IReviewer) =>
       backend.put(`reviewers/` + data.id, data), action.payload
    )
    yield put(updateSucceded(newStatus));
  } catch (e) {
    yield put(updateFailed(e));
  }
}

function* reviewersUpdateSaga() {
  yield takeEvery(update.type, runUpdateReviewer)
}

export default reviewersUpdateSaga