import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { add, 
         addSucceded, 
         addFailed, 
         IStatus } from "../../reducers/statusesSlice"
import { PayloadAction } from "@reduxjs/toolkit"


function* runCreateStatus(action: PayloadAction<IStatus>) {
  try {
    const newStatus: IStatus = yield call((data: IStatus) =>
       backend.post(`observations/statuses`, data), action.payload);
       
    yield put(addSucceded(newStatus));
  } catch (e) {
    yield put(addFailed(e));
  }
}

function* statusesCreateSaga() {
  yield takeEvery(add.type, runCreateStatus)
}

export default statusesCreateSaga