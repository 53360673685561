import React, { useContext, useState } from 'react';
import { AuthContext } from "../auth/AuthProvider";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

const UserMenu = ((props: MenuProps) => (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
));

const UserInfo = () => {

    const {userAccount, graphProfile, signOut} = useContext(AuthContext);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    
    if(userAccount) {
        return (
            <div style={{float: 'right'}} >
                <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                    endIcon={<ExpandMore></ExpandMore>}
                >
                    <span>
                        <div style={{textTransform: "none"}}>{userAccount?.userName}</div>
                        {graphProfile ? 
                        (<div style={{fontSize: "0.65rem", float: "right"}}>{graphProfile.officeLocation}</div>) : 
                        (<div>No location</div>)}
                    </span>
                </Button>
                <UserMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={signOut}>
                        <ListItemIcon>
                            <ExitToAppIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Sign out" />
                    </MenuItem>
                </UserMenu>
            </div>
        );
    } else {
        return (<></>);
    }
}

export default UserInfo;