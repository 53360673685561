import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import logo from '../images/logo.png';
import UserInfo from './UserInfo';

const NavMenu = () => {
    return (
        <header id="header">
            <Grid container>
                <Grid item xs={7} sm={6} md={5} lg={4} xl={3}>
                    <div id="logo">
                        <Link to="/"><img alt="Pharmascience" src={logo}/></Link>
                        <span>&nbsp;| Batch Record Review</span>
                    </div>
                </Grid>
                <Grid item xs={5} sm={6} md={7} lg={8} xl={9}>
                    <UserInfo />
                </Grid>
            </Grid>
        </header>
    );
};

export default NavMenu;
