import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { add, 
         addSucceded, 
         addFailed, 
         IClassification } from "../../reducers/classificationsSlice"
import { PayloadAction } from "@reduxjs/toolkit"


function* runCreateClassification(action: PayloadAction<IClassification>) {
  try {
    const newStatus: IClassification = yield call((data: IClassification) =>
       backend.post(`observations/classifications`, data), action.payload);
       
    yield put(addSucceded(newStatus));
  } catch (e) {
    yield put(addFailed(e));
  }
}

function* classificationCreateSaga() {
  yield takeEvery(add.type, runCreateClassification)
}

export default classificationCreateSaga