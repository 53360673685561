import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { add, 
         addSucceded, 
         addFailed, 
         ISystem } from "../../reducers/systemsSlice"
import { PayloadAction } from "@reduxjs/toolkit"


function* runCreateSystem(action: PayloadAction<ISystem>) {
  try {
    const newSystem: ISystem = yield call((data: ISystem) =>
       backend.post(`observations/systems`, data), action.payload);
       
    yield put(addSucceded(newSystem));
  } catch (e) {
    yield put(addFailed(e));
  }
}

function* systemCreateSaga() {
  yield takeEvery(add.type, runCreateSystem)
}

export default systemCreateSaga