import { UserAgentApplication, Logger, LogLevel, AuthenticationParameters } from "msal";

export const requiresInteraction = (errorMessage: any) => {
    if (!errorMessage || !errorMessage.length) {
        return false;
    }

    console.error(errorMessage);

    return (
        errorMessage.indexOf("consent_required") > -1 ||
        errorMessage.indexOf("interaction_required") > -1 ||
        errorMessage.indexOf("login_required") > -1
    );
};

export const fetchMsGraph = async (url: any, accessToken: any) => {
    const response = await fetch(url, {
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    });

    return response.json();
};

export const isIE = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ") > -1;
    const msie11 = ua.indexOf("Trident/") > -1;

    // If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
    // const isEdge = ua.indexOf("Edge/") > -1;

    return msie || msie11;
};

export const GRAPH_SCOPES = {
    OPENID: "openid",
    PROFILE: "profile",
    OFFLINE_ACCESS: "offline_access",
    USER_READ: "User.Read",
    WEB_API_ACCESS: "api://72013184-32fb-4555-9da2-0939526d396a/webapi"
};

export const GRAPH_ENDPOINTS = {
    ME: "https://graph.microsoft.com/v1.0/me",
};

export const microsoftGraphAuthenticationParams: AuthenticationParameters = {
    scopes: [
        GRAPH_SCOPES.OPENID,
        GRAPH_SCOPES.PROFILE,
        GRAPH_SCOPES.OFFLINE_ACCESS,
        GRAPH_SCOPES.USER_READ
    ],
    redirectUri: document.getElementById('root')?.baseURI
};

export const batchRecordGraphAuthenticationParams: AuthenticationParameters = {
    scopes: [
        GRAPH_SCOPES.WEB_API_ACCESS
    ],
    redirectUri: document.getElementById('root')?.baseURI
};


export const msalApp = new UserAgentApplication({
    auth: {
        clientId: "72013184-32fb-4555-9da2-0939526d396a",
        authority: "https://login.microsoftonline.com/0e459745-d53e-4d41-898c-683b4353aebd",
        redirectUri: document.getElementById('root')?.baseURI,
        validateAuthority: true,
        postLogoutRedirectUri: document.getElementById('root')?.baseURI,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: isIE()
    },
    system: {
        navigateFrameWait: 500,
        logger: new Logger((logLevel, message) => {
            console.log(message);
        }, {
            level: LogLevel.Error,
            piiLoggingEnabled: true
        }),
        telemetry: {
            applicationName: "pms_batch_record_review_app",
            applicationVersion: "1.0.0",
            telemetryEmitter: (events) => {
                console.log('Telemetry Events:', events);
            }
        }
    }
});
