import React, { useEffect, useState } from "react";
import { msalApp } from '../services/auth-utils';
import { Account, AuthenticationParameters } from "msal";
import backend from "../config/axios";
import * as MicrosoftGraph from "@microsoft/microsoft-graph-types"
import {
  requiresInteraction,
  fetchMsGraph,
  GRAPH_ENDPOINTS,
  microsoftGraphAuthenticationParams,
  batchRecordGraphAuthenticationParams
} from "../services/auth-utils";

type AuthContextType = {
  userAccount: Account | null;
  isAdmin: boolean;
  isSuperAdmin: boolean;
  graphProfile: MicrosoftGraph.User | null;
  authenticated: boolean;
  setUserAccount: any;
  signOut: any;
  signIn: any;
  loadingAuthState: boolean;
  authenticationError: boolean;
};

export const AuthContext = React.createContext<Partial<AuthContextType>>({});

export const AuthProvider = ({ children }: any) => {

  const [userAccount, setUserAccount] = useState(null as Account | null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [graphProfile, setGraphProfile] = useState(null as MicrosoftGraph.User | null);
  const [loadingAuthState, setLoadingAuthState] = useState(true);
  const [authenticationError, setAuthenticationError] = useState(false);

  const acquireToken = async (authenticationParams: AuthenticationParameters) => {
    try {
      return msalApp.acquireTokenSilent(authenticationParams);
    } catch (error) {
      if (requiresInteraction(error.errorCode)) {
        return msalApp.acquireTokenRedirect(authenticationParams);
      } else {
        console.log('Failed to acquire toke silently. Non-interactive error:', error);
      }
    }
  }

  const onSignIn = async () => {
    setAuthenticationError(false);
    setLoadingAuthState(true);

    return msalApp.loginRedirect(microsoftGraphAuthenticationParams);
  }

  const onSignOut = () => {
    msalApp.logout();
  }

  useEffect(() => {
    msalApp.handleRedirectCallback(error => {
      if (error) {
        const errorMessage = error.errorMessage ? error.errorMessage : "Unable to acquire access token.";
        console.error(errorMessage);
        setAuthenticationError(true);
        // setState works as long as navigateToLoginRequestUrl: false
        setLoadingAuthState(false);
      }
    });

    const acquireTokenAsync = async () => {
      const microsoftGraphTokenResponse = await acquireToken(microsoftGraphAuthenticationParams).catch(e => {
        ///set error state here
        console.error("Filed to acquire token. Error: " + e);
      });

      const batchRecordTokenResponse = await acquireToken(batchRecordGraphAuthenticationParams).catch(e => {
        ///set error state here
        console.error("Filed to acquire token. Error: " + e);
      });

      if (!microsoftGraphTokenResponse || !batchRecordTokenResponse) {
        await onSignIn();
      }
      else {
        setAuthenticationError(false);
        backend.defaults.headers.common['authorization'] = `Bearer ${batchRecordTokenResponse.accessToken}`


        // get the account
        let userAccount = msalApp.getAccount();
        if (userAccount) {

          setUserAccount(userAccount);

          let userRoles: any = userAccount.idToken.roles;
          console.log(`Roles :  ${JSON.stringify(userRoles)}`)
          if (userRoles && userRoles instanceof Array) {
            if (userRoles.find((role: string) => role === 'Admin')) {
              setIsAdmin(true);
            }
            if (userRoles.find((role: string) => role === 'SuperAdmin')) {
              setIsAdmin(true);
              setIsSuperAdmin(true);
            }
          }
        }

        const graphProfile = await fetchMsGraph(
          GRAPH_ENDPOINTS.ME,
          microsoftGraphTokenResponse.accessToken
        ).catch((error) => {
          // set error here
          console.error("Failed to fetch MS Graph. Error: " + error);
        });

        if (graphProfile) {
          // set graph profile
          setGraphProfile(graphProfile);
        }

        setLoadingAuthState(false);
      }
    };

    acquireTokenAsync();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        userAccount,
        isAdmin,
        isSuperAdmin,
        graphProfile,
        authenticated: userAccount !== null,
        setUserAccount,
        signOut: onSignOut,
        signIn: onSignIn,
        loadingAuthState,
        authenticationError,
      }}>
      {!loadingAuthState ? children : null}
    </AuthContext.Provider>
  );
}