import React, { useContext } from 'react'
import {
    TextField,
    Dialog,
    DialogActions,
    Button,
    IconButton,
    Tooltip,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSelector, useDispatch } from "react-redux"
import { AppState } from "../reducers/rootReducer"
import { fetch, add, update, remove, IReviewer } from "../reducers/reviewersSlice"
import { AppDispatch } from "../store"
import EditIcon from '@material-ui/icons/Edit';
import MaterialTable from 'material-table';
import { AuthContext } from "../auth/AuthProvider"

type Props = {
    type: string,
    defaultValue?: string,
    onNewValue?: (newValue: string) => void
}

const ReviewerSelector = ({ type, defaultValue, onNewValue }: Props) => {

    const state = useSelector((state: AppState) => state.reviewers);
    const dispatch = useDispatch<AppDispatch>();
    const { isAdmin } = useContext(AuthContext);
    const [open, toggleOpen] = React.useState(false);

    const handleClose = () => {
        toggleOpen(false);
    };

    if (state.items == null &&
        !state.isLoading &&
        !state.isError) {
        dispatch(fetch())
    }

    const getFilteredList = () => {
        if (state.items) {
            if (type === "prod") {
                return state.items.filter(row => (row.type === 1)).map(row => ({ ...row }));
            } else if (type === "qa") {
                return state.items.filter(row => (row.type === 2)).map(row => ({ ...row }));
            }
        }

        return [];
    }

    const reviewersList = getFilteredList();

    return (
        <React.Fragment>
            <table>
                <tbody>
                    <tr>
                        <td style={{ width: '100%' }}>
                            <Autocomplete
                                id="reviewer"
                                disableClearable
                                options={reviewersList.map((option) => option.name)}
                                fullWidth
                                defaultValue={defaultValue}
                                key={defaultValue}
                                onChange={(event: any, newValue: any, reason: any) => {
                                    if (onNewValue) {
                                        onNewValue(newValue);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={type == 'prod' ? "Reviewer Prod" : "Reviewer QA"}
                                        name="name"
                                        size="small"
                                        disabled={state.isLoading}
                                    />
                                )} />
                        </td>
                        <td>
                            {isAdmin ?
                                <Tooltip title="Edit">
                                    <IconButton style={{ color: "black", padding: "5px" }} size="medium" aria-label="edit" onClick={() => { toggleOpen(true) }} >
                                        <EditIcon fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                                : null}
                        </td>
                    </tr>
                </tbody>
            </table>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                <MaterialTable
                    title="Edit Reviewers"
                    columns={[
                        { title: 'Name', field: 'name' }
                    ]}
                    data={reviewersList}
                    style={{ margin: '20px' }}
                    options={{
                        padding: 'dense',
                        paging: false,
                        addRowPosition: 'first',
                        actionsColumnIndex: -1,
                        maxBodyHeight: 400,
                        search: false
                    }}
                    localization={{ header: { actions: '' } }}
                    editable={{
                        onRowAdd: (newData) =>
                            new Promise((resolve: any) => {
                                setTimeout(() => {
                                    resolve();
                                    var newReviewer = {
                                        type: type === 'prod' ? 1 : 2,
                                        name: newData.name
                                    }
                                    dispatch(add(newReviewer));
                                }, 600);
                            }),
                        onRowUpdate: (updateData) =>
                            new Promise((resolve: any) => {
                                setTimeout(() => {
                                    resolve();
                                    dispatch(update(updateData))
                                }, 600);
                            }),
                        onRowDelete: (deleteData) =>
                            new Promise((resolve: any) => {
                                setTimeout(() => {
                                    resolve();
                                    dispatch(remove(deleteData))
                                }, 600);
                            }),
                    }}>
                </MaterialTable>
                <DialogActions>
                    <Button onClick={handleClose} >
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default ReviewerSelector;