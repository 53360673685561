import { PayloadAction } from "@reduxjs/toolkit";
import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { fetchReviews, fetchReviewsSucceeded, fetchReviewsFailed, IReview } from "../../reducers/reviewsSlice"

function* runFetchReviews(action: PayloadAction<any>) {
  try {
    const reviews: Array<IReview> = yield call(() =>
       backend.get(`reviews?siteId=${action.payload.siteId}&isArchived=${action.payload.archived ? "true" : "false"}` )
    )
    yield put(fetchReviewsSucceeded(reviews));
  } 
  catch (e) {
    yield put(fetchReviewsFailed(e))
  }
}

function* fetchReviewsSaga() {
  yield takeEvery(fetchReviews.type, runFetchReviews)
}

export default fetchReviewsSaga