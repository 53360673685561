import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { INotificationMessage } from "../services/notification"

export interface IDepartment {
    id?: number
    name?: string
}

export interface IDepartmentsState {
    items: Array<IDepartment> | undefined,
    isLoading: boolean,
    isError: boolean
    message: INotificationMessage | undefined,
}

const initialState: IDepartmentsState = {
    items: undefined,
    isLoading: false,
    isError: false,
    message: undefined,
}

const reviewSlice = createSlice({
    name: "department",
    initialState,
    reducers: {
        add: (state: IDepartmentsState, action: PayloadAction<IDepartment>) => {
            state.isError = false;
            state.isLoading = true;
        },
        addSucceded: (state: IDepartmentsState, action: PayloadAction<IDepartment>) => {
            state.isLoading = false;
            state.items = [action.payload].concat(state.items ?? []).sort(function (a, b) { return (a.id ?? 0) - (b.id ?? 0) });
            state.isError = false;
        },
        addFailed: (state: IDepartmentsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isError = true;
            state.message = { message: 'Error on adding new department', variant: 'error' }
        },
        fetchDepartments: (state: IDepartmentsState) => {
            state.message = undefined;
            state.isLoading = true;
            state.isError = false;
        },
        fetchDepartmentsSucceded: (state: IDepartmentsState, action: PayloadAction<Array<IDepartment>>) => {
            state.items = action.payload;
            state.isLoading = false;
            state.message = undefined;
            state.isError = false;
        },
        fetchDepartmentsFailed: (state: IDepartmentsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.message = { message: 'Error on getting the department list', variant: "error" };
            state.isError = true;
        },
        update: (state: IDepartmentsState, action: PayloadAction<IDepartment>) => {
            state.isError = false;
        },
        updateSucceded: (state: IDepartmentsState, action: PayloadAction<IDepartment>) => {
            state.isLoading = false;
            if (state.items) {
                let updateIndex = state.items?.findIndex(x => x.id === action.payload.id);
                if (updateIndex >= 0) {
                    state.items[updateIndex] = action.payload;
                }
            }

            state.isError = false;
        },
        updateFailed: (state: IDepartmentsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isError = true;
            state.message = { message: 'Failed to update status', variant: 'error' }
        },
        remove: (state: IDepartmentsState, action: PayloadAction<IDepartment>) => {
            state.isError = false;
        },
        removeSucceded: (state: IDepartmentsState, action: PayloadAction<number>) => {
            state.isLoading = false;
            if (state.items) {
                state.items.splice(state.items.findIndex(x => x.id === action.payload), 1);
            }
            state.isError = false;
        },
        removeFailed: (state: IDepartmentsState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.isError = true;
            state.message = { message: 'Failed to update status', variant: 'error' }
        },
        resetMessage: (state: IDepartmentsState) => {
            state.message = undefined;
            state.isError = false;
        }
    },
})

export const {
    add,
    addSucceded,
    addFailed,
    update,
    updateSucceded,
    updateFailed,
    remove,
    removeSucceded,
    removeFailed,
    fetchDepartments,
    fetchDepartmentsSucceded,
    fetchDepartmentsFailed,
    resetMessage } = reviewSlice.actions

export default reviewSlice