import React, { useContext } from 'react'
import {
    IconButton,
    Tooltip,
    Paper,
    Typography,
    Grid
} from '@material-ui/core'
import { useSelector, useDispatch } from "react-redux"
import { AppState } from "../reducers/rootReducer"
import { fetch } from "../reducers/observationsSlice"
import { AppDispatch } from "../store"
import AddIcon from '@material-ui/icons/Add'
import { IObservation, add } from "../reducers/observationsSlice"
import ObservationItem from './ObservationItem'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableHead from '@material-ui/core/TableHead'
import MuiTableCell from "@material-ui/core/TableCell"
import { withStyles } from '@material-ui/core/styles'
import SystemEdit from './SystemEdit'
import ClassificationEdit from './ClassificationEdit'
import StatusEdit from './StatusEdit'
import { useEffect } from 'react'
import { updateObservationCount } from '../reducers/reviewsSlice'
import { AuthContext } from "../auth/AuthProvider"
import { SatelliteSharp } from '@material-ui/icons'

const TableCell = withStyles({
    root: {
        borderBottom: "none",
        paddingTop: 10,
        paddingRight: 8,
        paddingBottom: 10,
        paddingLeft: 8,
        "&:last-child": {
            paddingRight: 0
        }
    }
})(MuiTableCell);

type Props = {
    reviewId: number,
}

const Observations = ({ reviewId }: Props) => {

    const state = useSelector((state: AppState) => state.observations);
    const { isAdmin, isSuperAdmin } = useContext(AuthContext);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        return () => {
            dispatch(updateObservationCount({ id: reviewId, numberOfObservations: state.items ? state.items.length : 0 }))
        };
    }, [state.items]);

    if ((!state.items || (state.reviewId !== reviewId)) && !state.isLoading && !state.isError) {
        dispatch(fetch(reviewId));
    }

    return (
        <React.Fragment>
            <Paper style={{ marginTop: '20px', padding: '15px' }}>
                <Typography variant="h6" style={{ marginLeft: "10px", marginBottom: "10px", float: 'left' }}>Observations</Typography>
                <Tooltip title="Add">
                    <IconButton
                        style={{ color: "black", padding: "5px", float: 'right' }}
                        size="medium"
                        aria-label="edit"
                        onClick={() => {
                            dispatch(
                                add({ reviewId: reviewId, status: "OPEN" } as IObservation)
                            )
                        }}>
                        <AddIcon fontSize="inherit" />
                    </IconButton>
                </Tooltip>
                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>#</TableCell>
                            <TableCell width="10%">Location</TableCell>
                            <TableCell component="th" scope="row">Observation</TableCell>
                            <TableCell>
                                <Grid container wrap='nowrap'>
                                    <Grid item lg={true} md={true} sm={true} xs={true}>System</Grid>
                                    {isAdmin ?
                                        <Grid item><SystemEdit /></Grid>
                                        : null}
                                </Grid>
                            </TableCell>
                            <TableCell>
                                <Grid container wrap='nowrap'>
                                    <Grid item lg={true} md={true} sm={true} xs={true}>Classification</Grid>
                                    {isAdmin ?
                                        <Grid item><ClassificationEdit /></Grid>
                                        : null}
                                </Grid>
                            </TableCell>
                            <TableCell component="th" scope="row">Answer</TableCell>
                            <TableCell>
                                <Grid container wrap='nowrap'>
                                    <Grid item lg={true} md={true} sm={true} xs={true}>Status</Grid>
                                    {isAdmin ?
                                        <Grid item><StatusEdit /></Grid>
                                        : null}
                                </Grid>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            (state.items && state.items.length > 0) ?
                                state.items?.map(row => <ObservationItem value={row} isSuperAdmin={isSuperAdmin} />) :
                                <TableRow>
                                    <TableCell colSpan={7} align="center">
                                        No Observations
                                    </TableCell>
                                </TableRow>
                        }
                    </TableBody>
                </Table>
            </Paper>
        </React.Fragment>
    );
};

export default Observations;