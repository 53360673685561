import React from 'react'
import { 
    TextField, 
    Dialog,
    DialogActions,
    Button,
    IconButton, 
    Tooltip, 
    Grid  } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSelector, useDispatch } from "react-redux"
import { AppState } from "../reducers/rootReducer"
import { fetch, add, update, remove, ISystem } from "../reducers/systemsSlice"
import { AppDispatch } from "../store"
import EditIcon from '@material-ui/icons/Edit';
import MaterialTable from 'material-table';

const SystemEdit = () => {

    const state = useSelector((state: AppState) => state.systems);
    const dispatch = useDispatch<AppDispatch>();
    const [open, toggleOpen] = React.useState(false);
    
    const handleClose = () => {
        toggleOpen(false);
    };

    if(state.items == null && 
       !state.isLoading && 
       !state.isError){
       dispatch(fetch())
    } 

    const systemsTableData = state.items ? state.items.map(row => ({ ...row } )) : [];
    
    return (
        <React.Fragment>
            <Tooltip title="Edit">
                <IconButton style={{color: "black", padding: "5px"}} 
                            size="small" 
                            aria-label="edit" 
                            onClick={() => {
                                toggleOpen(true)
                            }}>
                    <EditIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Dialog 
                disableBackdropClick
                disableEscapeKeyDown
                open={open} 
                onClose={handleClose} 
                aria-labelledby="form-dialog-title">
                <MaterialTable  
                    title="Edit System List"
                    columns={[
                        { title:'System', field: 'name' }
                    ]}
                    data={systemsTableData}
                    style={{margin: '20px'}}
                    options={{ 
                        padding: 'dense',
                        paging: false,
                        addRowPosition: 'first',
                        actionsColumnIndex: -1,
                        maxBodyHeight: 400,
                        search: false
                    }}
                    localization={{header: {actions: ''}}}
                    editable={{
                        onRowAdd: (newData: ISystem) =>
                            new Promise((resolve:any) => {
                                    setTimeout(() => {
                                        resolve();
                                        dispatch(add(newData));
                                    }, 600);
                        }),
                        onRowUpdate: (updateData: ISystem) =>
                            new Promise((resolve:any) => {
                                    setTimeout(() => {
                                        resolve();
                                        dispatch(update(updateData))
                                    }, 600);
                        }),
                        onRowDelete: (deleteData: ISystem) =>
                            new Promise((resolve:any) => {
                                    setTimeout(() => {
                                        resolve();
                                        dispatch(remove(deleteData))
                                    }, 600);
                        }),
                    }}>
                </MaterialTable>
                <DialogActions>
                    <Button onClick={handleClose} >
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default SystemEdit;