import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { 
    ISystem, 
    update, 
    updateSucceded, 
    updateFailed } from "../../reducers/systemsSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runUpdateSystem(action: PayloadAction<ISystem>) {
  try {
    const newSystem: ISystem = yield call((data: ISystem) =>
       backend.put(`observations/systems/` + data.id, data), action.payload
    )
    yield put(updateSucceded(newSystem));
  } catch (e) {
    yield put(updateFailed(e));
  }
}

function* systemsUpdateSaga() {
  yield takeEvery(update.type, runUpdateSystem)
}

export default systemsUpdateSaga