import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { 
    IStatus, 
    update, 
    updateSucceded, 
    updateFailed } from "../../reducers/statusesSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runUpdateStatus(action: PayloadAction<IStatus>) {
  try {
    const newStatus: IStatus = yield call((data: IStatus) =>
       backend.put(`observations/statuses/` + data.id, data), action.payload
    )
    yield put(updateSucceded(newStatus));
  } catch (e) {
    yield put(updateFailed(e));
  }
}

function* statusesUpdateSaga() {
  yield takeEvery(update.type, runUpdateStatus)
}

export default statusesUpdateSaga