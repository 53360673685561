import backend from "../../config/axios"
import { takeEvery, call, put } from "redux-saga/effects"
import { IClassification, 
         fetch, 
         fetchSucceded, 
         fetchFailed} from "../../reducers/classificationsSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runFetchClassifications(action: PayloadAction<IClassification>) {
    try {
        const classifications: Array<IClassification> = yield call(() => backend.get(`observations/classifications`));
        yield put(fetchSucceded(classifications));
    } catch (e) {
        yield put(fetchFailed(e));
    }
}

function* classificationsFetchSaga() {
    yield takeEvery(fetch.type, runFetchClassifications)
}

export default classificationsFetchSaga