import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { INotificationMessage } from "../services/notification"

export interface IReviewStatus {
    id?: number
    name?: string
}

export interface IReviewStatusesState {
    items: Array<IReviewStatus> | undefined,
    isLoading: boolean,
    isError: boolean
    message: INotificationMessage | undefined,
}

const initialState: IReviewStatusesState = {
    items: undefined,
    isLoading: false,
    isError: false,
    message: undefined,
}

const reviewStatusesSlice = createSlice({
    name: "reviewStatuses",
    initialState,
    reducers: {
        fetch: (state: IReviewStatusesState) => {
            state.message = undefined;
            state.isLoading = true;
            state.isError = false;
        },
        fetchSucceded: (state: IReviewStatusesState, action: PayloadAction<Array<IReviewStatus>>) => {
            state.items = action.payload;
            state.isLoading = false;
            state.message = undefined;
            state.isError = false;
        },
        fetchFailed: (state: IReviewStatusesState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.message = { message: 'Error on getting the review status list', variant: "error" };
            state.isError = true;
        },
        add: (state: IReviewStatusesState, action: PayloadAction<IReviewStatus>) => {
            state.isError = false;
            state.isLoading = true;
        },
        addSucceded: (state: IReviewStatusesState, action: PayloadAction<IReviewStatus>) => {  
            state.isLoading = false;
            state.items = [action.payload].concat(state.items ?? []).sort(function (a, b) { return (a.id ?? 0) - (b.id ?? 0) });
            state.isError = false;
        },
        addFailed: (state: IReviewStatusesState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.isError = true;
            state.message = {message: 'Error on adding review', variant: 'error'}
        },
        update: (state: IReviewStatusesState, action: PayloadAction<IReviewStatus>) => {
            state.isError = false;
        },
        updateSucceded: (state: IReviewStatusesState, action: PayloadAction<IReviewStatus>) => {  
            state.isLoading = false;
            if(state.items) {
                let updateIndex = state.items?.findIndex(x => x.id === action.payload.id);
                if(updateIndex >= 0) {
                    state.items[updateIndex] = action.payload;
                }
            }

            state.isError = false;
        },
        updateFailed: (state: IReviewStatusesState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.isError = true;
            state.message = {message: 'Failed to update review status', variant: 'error'}
        },
        remove: (state: IReviewStatusesState, action: PayloadAction<IReviewStatus>) => {
            state.isError = false;
        },
        removeSucceded: (state: IReviewStatusesState, action: PayloadAction<number>) => {  
            state.isLoading = false;
            if(state.items) {
                // let updateIndex = state.items?.findIndex(x => x.id === action.payload);
                // if(updateIndex >= 0) {
                //     let statusToUpdate = state.items[updateIndex];
                //     statusToUpdate.name = action.payload.name;

                //     state.items[updateIndex] = statusToUpdate;
                // }

                state.items.splice(state.items.findIndex(x => x.id === action.payload), 1);
            }

            state.isError = false;
        },
        removeFailed: (state: IReviewStatusesState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.isError = true;
            state.message = {message: 'Failed to update review status', variant: 'error'}
        },
        resetMessage: (state: IReviewStatusesState) => {
            state.message = undefined;
            state.isError = false;
        }   
    },
})

export const { 
    fetch, 
    fetchSucceded, 
    fetchFailed,
    add, 
    addSucceded, 
    addFailed,
    update, 
    updateSucceded, 
    updateFailed,
    remove, 
    removeSucceded, 
    removeFailed,
    resetMessage } = reviewStatusesSlice.actions

export default reviewStatusesSlice