import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { add, addSucceded, addFailed, IObservation } from "../../reducers/observationsSlice"
import { PayloadAction } from "@reduxjs/toolkit"


function* runCreateObservation(action: PayloadAction<IObservation>) {
  try {
    const newObservation: IObservation = yield call((data: IObservation) =>
       backend.post(`observations`, data), action.payload
    )
    yield put(addSucceded(newObservation));
  } catch (e) {
    yield put(addFailed(e));
  }
}

function* observationCreateSaga() {
  yield takeEvery(add.type, runCreateObservation)
}

export default observationCreateSaga