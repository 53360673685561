import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { 
  archiveReview, 
  archiveReviewSucceded, 
  archiveReviewFailed, 
  IReviewPatch, 
  IReview } from "../../reducers/reviewsSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runArchiveReview(action: PayloadAction<number>) {
  try {
    const review: IReview = yield call((data: IReviewPatch) =>
      backend.patch(
        `reviews/` + data.id, data.patch), 
        {
          id: action.payload, 
          patch: [{ 
              op: "replace", 
              path: "/archived", 
              value: "true" 
          }]
        } as IReviewPatch
    );

    yield put(archiveReviewSucceded(review));
    
  } catch (e) {
    yield put(archiveReviewFailed(e));
  }
}

function* archiveReviewSaga() {
  yield takeEvery(archiveReview.type, runArchiveReview)
}

export default archiveReviewSaga