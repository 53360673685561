import backend from "../../config/axios"
import { takeEvery, call, put } from "redux-saga/effects"
import { IReviewer, 
         fetch, 
         fetchSucceded, 
         fetchFailed} from "../../reducers/reviewersSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runfetchReviewers(action: PayloadAction<IReviewer>) {
    try {
        const reviewers: Array<IReviewer>  = yield call(() => backend.get(`reviewers`));
        yield put(fetchSucceded(reviewers));
    } catch (e) {
        yield put(fetchFailed(e));
    }
}

function* reviewersFetchSaga() {
    yield takeEvery(fetch.type, runfetchReviewers)
}

export default reviewersFetchSaga