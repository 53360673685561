import React from 'react';
import backend from "../config/axios"
import { useSnackbar } from 'notistack'

const ImportDataForm = () => {
    const { enqueueSnackbar } = useSnackbar();

    var reviewFile: any;
    var observationFile: any;

    function onSelectReviewFile(e: any) {
        reviewFile = e.target.files[0];
    }

    function onSelectObservationFile(e: any) {
        observationFile = e.target.files[0];
    }

    function importReview(e: any) {
        let url = "Import/Reviews";
        let file = reviewFile;
        uploadFile(url, file, "Reviews were successfully imported", "Error while importing reviews");
    }

    function importObservations(e: any) {
        let url = "Import/Observations";
        let file = observationFile;
        uploadFile(url, file, "Observations were successfully imported", "Error while importing observations");
    }

    function uploadFile(url: string, file: string, onSuccessMessage: string, onErrorMessage: string) {
        let formData = new FormData();
        formData.append("file", file);
        backend.post(url, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }).then((response) => {
            enqueueSnackbar(onSuccessMessage, { variant: 'success' });
        }).catch((error) => {
            enqueueSnackbar(onErrorMessage + '\r\n' + error, { variant: 'error' });
        });
    };

    return (
        <React.Fragment>
            <h2>
                Import Data
            </h2>
            <p>
                This page allow you to import initiale dataset from multiples excel files.
                Review data must be imported first. If you import observation before the associated reviews, it will fail.
            </p>

            <h3>Reviews Import</h3>
            <p></p>
            <div style={{ padding: '10px' }}>
                <label></label>
                <input onChange={onSelectReviewFile} type="file" accept=".xlsx" />
            </div>
            <div style={{ padding: '10px' }}>
                <button onClick={importReview} >Import</button>
            </div>

            <h3>Observations Import</h3>
            <p></p>
            <div style={{ padding: '10px' }}>
                <label></label>
                <input onChange={onSelectObservationFile} type="file" accept=".xlsx" />
            </div>
            <div style={{ padding: '10px' }}>
                <button onClick={importObservations} >Import</button>
            </div>
        </React.Fragment>
    );
}

export default ImportDataForm;