import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { 
  updateReview, 
  updateReviewSucceded, 
  updateReviewFailed, 
  IReview } from "../../reducers/reviewsSlice"
import { PayloadAction } from "@reduxjs/toolkit"


function* runUpdateReviews(action: PayloadAction<IReview>) {
  try {
    yield call((data: IReview) =>
      backend.put(`reviews/` + data.id, data), action.payload
    )
    yield put(updateReviewSucceded(action.payload));
  } catch (e) {
    yield put(updateReviewFailed(e));
  }
}

function* reviewsUpdateSaga() {
  yield takeEvery(updateReview.type, runUpdateReviews)
}

export default reviewsUpdateSaga