import React from 'react'
import {
    Dialog,
    DialogActions,
    Button,
    IconButton,
    Tooltip,
} from '@material-ui/core'
import { useSelector, useDispatch } from "react-redux"
import { AppState } from "../reducers/rootReducer"
import { fetchDepartments, add, update, remove, IDepartment } from "../reducers/departmentsSlice"
import { AppDispatch } from "../store"
import EditIcon from '@material-ui/icons/Edit';
import MaterialTable from 'material-table';

const DepartmentEdit = () => {

    const state = useSelector((state: AppState) => state.departments);
    const dispatch = useDispatch<AppDispatch>();
    const [open, toggleOpen] = React.useState(false);

    const handleClose = () => {
        toggleOpen(false);
    };

    if (state.items == null &&
        !state.isLoading &&
        !state.isError) {
        dispatch(fetchDepartments())
    }

    const departmentsTableData = state.items ? state.items.map(row => ({ ...row })) : [];

    return (
        <React.Fragment>
            <Tooltip title="Edit">
                <IconButton style={{ color: "black", padding: "5px" }}
                    size="medium"
                    aria-label="edit"
                    onClick={() => {
                        toggleOpen(true)
                    }} >
                    <EditIcon fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title">
                <MaterialTable
                    title="Edit Department List"
                    columns={[
                        { title: 'Department', field: 'name' }
                    ]}
                    data={departmentsTableData}
                    style={{ margin: '20px' }}
                    options={{
                        padding: 'dense',
                        paging: false,
                        addRowPosition: 'first',
                        actionsColumnIndex: -1,
                        maxBodyHeight: 400,
                        search: false
                    }}
                    localization={{ header: { actions: '' } }}
                    editable={{
                        onRowAdd: (newData: IDepartment) =>
                            new Promise((resolve: any) => {
                                setTimeout(() => {
                                    resolve();
                                    dispatch(add(newData));
                                }, 600);
                            }),
                        onRowUpdate: (updateData: IDepartment) =>
                            new Promise((resolve: any) => {
                                setTimeout(() => {
                                    resolve();
                                    dispatch(update(updateData))
                                }, 600);
                            }),
                        onRowDelete: (deleteData: IDepartment) =>
                            new Promise((resolve: any) => {
                                setTimeout(() => {
                                    resolve();
                                    dispatch(remove(deleteData))
                                }, 600);
                            }),
                    }}>
                </MaterialTable>
                <DialogActions>
                    <Button onClick={handleClose} >
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}

export default DepartmentEdit;