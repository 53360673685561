import { all, takeEvery } from "redux-saga/effects"
import { PayloadActionWithResolve } from "../reducers/rootReducer"
import reviewListFetchSaga from "./reviews/fetch"
import reviewListCreateSaga from "./reviews/create"
import fetchSingleReviewSaga from "./reviews/fetchSingle"
import departmentFetchSaga from "./departments/fetch"
import departmentsCreateSaga from "./departments/create"
import departmentsRemoveSaga from "./departments/remove"
import departmentsUpdateSaga from "./departments/update"
import reviewStatusFetchSaga from "./reviewStatuses/fetch"
import reviewStatusCreateSaga from "./reviewStatuses/create"
import reviewStatusUpdateSaga from "./reviewStatuses/update"
import reviewStatusRemoveSaga from "./reviewStatuses/remove"
import reviewsUpdateSaga from "./reviews/update"
import reviewsPartialUpdateSaga from "./reviews/partialUpdate"
import reviewsArchiveSaga from "./reviews/archive"
import reviewsUnarchiveSaga from "./reviews/unarchive"
import observationFetchSaga from "./observations/fetch"
import observationRemoveSaga from "./observations/remove"
import statusFetchSaga from "./statuses/fetch"
import statusCreateSaga from "./statuses/create"
import statusUpdateSaga from "./statuses/update"
import statusRemoveSaga from "./statuses/remove"
import classificationFetchSaga from "./classifications/fetch"
import classificationCreateSaga from "./classifications/create"
import classificationUpdateSaga from "./classifications/update"
import classificationRemoveSaga from "./classifications/remove"
import systemFetchSaga from "./systems/fetch"
import systemCreateSaga from "./systems/create"
import systemUpdateSaga from "./systems/update"
import systemRemoveSaga from "./systems/remove"
import observationCreateSaga from "./observations/create"
import observationUpdateSaga from "./observations/update"
import observationPartialUpdateSaga from "./observations/partialUpdate"
import reviewersFetchSaga from "./reviewers/fetch"
import reviewersCreateSaga from "./reviewers/create"
import reviewersUpdateSaga from "./reviewers/update"
import reviewersRemoveSaga from "./reviewers/remove"
import deleteReviewSaga from "./reviews/delete"


export default function* rootSaga() {
  // Attach an "onResolve" method on any
  // payload dispatched by a Saga so it can be
  // called here and you get feedback from within your component
  yield takeEvery(
    "*",
    (
      action: PayloadActionWithResolve<any> & {
        "@@redux-saga/SAGA_ACTION": boolean
      },
    ) => {
      if (action.onResolve && action["@@redux-saga/SAGA_ACTION"]) {
        action.onResolve(action.payload)
      }
    },
  )

  yield all([
    reviewListFetchSaga(),
    reviewListCreateSaga(),
    departmentFetchSaga(),
    departmentsCreateSaga(),
    departmentsRemoveSaga(),
    departmentsUpdateSaga(),
    reviewStatusFetchSaga(),
    reviewStatusCreateSaga(),
    reviewStatusUpdateSaga(),
    reviewStatusRemoveSaga(),
    reviewsUpdateSaga(),
    reviewsPartialUpdateSaga(),
    reviewsArchiveSaga(),
    reviewsUnarchiveSaga(),
    deleteReviewSaga(),
    observationFetchSaga(),
    statusFetchSaga(),
    statusCreateSaga(),
    statusUpdateSaga(),
    statusRemoveSaga(),
    classificationFetchSaga(),
    classificationCreateSaga(),
    classificationUpdateSaga(),
    classificationRemoveSaga(),
    systemFetchSaga(),
    systemCreateSaga(),
    systemUpdateSaga(),
    systemRemoveSaga(),
    observationCreateSaga(),
    observationUpdateSaga(),
    observationPartialUpdateSaga(),
    observationRemoveSaga(),
    fetchSingleReviewSaga(),
    reviewersFetchSaga(),
    reviewersCreateSaga(),
    reviewersUpdateSaga(),
    reviewersRemoveSaga()
  ])
}
