import backend from "../../config/axios"
import { takeEvery, call, put } from "redux-saga/effects"
import {
    IDepartment,
    fetchDepartments,
    fetchDepartmentsSucceded,
    fetchDepartmentsFailed
} from "../../reducers/departmentsSlice"
import { PayloadAction } from "@reduxjs/toolkit"


function* runFetchDepartments(action: PayloadAction<IDepartment>) {
    try {
        const review: Array<IDepartment> = yield call(() => backend.get(`departments`));
        yield put(fetchDepartmentsSucceded(review));
    } catch (e) {
        yield put(fetchDepartmentsFailed(e));
    }
}

function* departmentFetchSaga() {
    yield takeEvery(fetchDepartments.type, runFetchDepartments)
}

export default departmentFetchSaga