import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { INotificationMessage } from "../services/notification"

export interface IObservation {
    id: number
    reviewId: number
    number: number
    location: string
    observation: string
    system: string
    classification?: string
    status?: string
    answer: string
}

export interface IObservationExport extends IObservation {
    reviewDescription: string
    reviewCode: string
    reviewLot: string
    reviewBulkLot: string
    reviewDepartment: string
    reviewMfgStartDate: Date
    reviewReviewerProd: string
    reviewReviewerQa: string
    reviewStatus: string,
}

export interface IObservationsState {
    reviewId: number | undefined
    items: Array<IObservation> | undefined
    isLoading: boolean
    message: INotificationMessage | undefined
    isError: boolean,
}

export interface IObservationPatch {
    id: number,
    patch: Array<any>
}

const initialState: IObservationsState = {
    reviewId: undefined,
    items: undefined,
    isLoading: false,
    message: undefined,
    isError: false
}

const observationsSlice = createSlice({
    name: "observation",
    initialState,
    reducers: {
        fetch: (state: IObservationsState, action: PayloadAction<number>) => {
            state.reviewId = action.payload;
            state.message = undefined;
            state.isLoading = true;
            state.isError = false;
        },
        fetchSucceded: (state: IObservationsState, action: PayloadAction<Array<IObservation>>) => {
            state.isLoading = false;
            state.message = undefined;
            state.isError = false;
            state.items = action.payload;
        },
        fetchFailed: (state: IObservationsState, action: PayloadAction<any> ) => {
            //state.reviewId = undefined;
            state.isLoading = false;
            state.message = { message: 'Failed to fetch observations', variant: "error" };
            state.isError = true;
        },
        add: (state: IObservationsState, action: PayloadAction<IObservation>) => {
            state.isError = false;
        },
        addSucceded: (state: IObservationsState, action: PayloadAction<IObservation>) => {  
            state.isLoading = false;
            if(state.items) {
                state.items.push(action.payload);
            }
            state.isError = false;
        },
        addFailed: (state: IObservationsState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.isError = true;
            state.message = {message: 'Failed to create observation', variant: 'error'}
        },
        update: (state: IObservationsState, action: PayloadAction<IObservation>) => {
            state.isError = false;
        },
        updateSucceded: (state: IObservationsState, action: PayloadAction<IObservation>) => {  
            state.isLoading = false;
            if(state.items) {
                let updateIndex = state.items?.findIndex(x => x.id === action.payload.id);
                if(updateIndex >= 0) {
                    let observationToUpdate = state.items[updateIndex];
                    observationToUpdate.answer = action.payload.answer;
                    observationToUpdate.observation = action.payload.observation;
                    observationToUpdate.location = action.payload.location;
                    observationToUpdate.classification = action.payload.classification;
                    observationToUpdate.status = action.payload.status;
                    observationToUpdate.system = action.payload.system;

                    state.items[updateIndex] = observationToUpdate;
                }
            }
            state.isError = false;
        },
        updateFailed: (state: IObservationsState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.isError = true;
            state.message = {message: 'Failed to update observation', variant: 'error'}
        },
        partialUpdate: (state: IObservationsState, action: PayloadAction<IObservationPatch>) => {
            state.message = undefined;
            state.isLoading = true;
        },
        partialUpdateSucceded: (state: IObservationsState, action: PayloadAction<IObservation>) => {  
            state.isLoading = false;
            state.message = undefined;
            state.isError = false;

            const { id, ...rest } = action.payload

            if(state.items){
                state.items = state.items.map(observation => {
                    if (observation.id === id) {
                        return { ...observation, ...rest }
                    }
                    return observation
                })
            }
        },
        partialUpdateFailed: (state: IObservationsState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.message = { message: 'Error on saving review changes', variant: "error" };
            state.isError = true;
        },
        remove: (state: IObservationsState, action: PayloadAction<number>) => {
            state.isLoading = true;
            state.message = undefined;
            state.isError = false;
        },
        removeSucceded: (state: IObservationsState, action: PayloadAction<number>) => {  
            if(state.items) {
                state.items.splice(state.items.findIndex(x => x.id === action.payload), 1);
            }
            state.isLoading = false;
            state.isError = false;
        },
        removeFailed: (state: IObservationsState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.isError = true;
            state.message = {message: 'Failed to delete observation', variant: 'error'}
        },
    },
})

export const { 
    fetch,
    fetchSucceded,
    fetchFailed, 
    add,
    addSucceded,
    addFailed,
    update,
    updateSucceded,
    updateFailed,
    partialUpdate,
    partialUpdateSucceded,
    partialUpdateFailed,
    remove,
    removeSucceded,
    removeFailed
} = observationsSlice.actions

export default observationsSlice