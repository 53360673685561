import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { 
  partialUpdate, 
  partialUpdateSucceded, 
  partialUpdateFailed, 
  IObservationPatch, 
  IObservation } from "../../reducers/observationsSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runPartialUpdateObservations(action: PayloadAction<IObservationPatch>) {
    try {
        const observation: IObservation = yield call((data: IObservationPatch) =>
            backend.patch(`observations/` + data.id, data.patch), action.payload
        );   
        yield put(partialUpdateSucceded(observation));
    } catch (e) {
        yield put(partialUpdateFailed(e));
    }
}

function* observationPartialUpdateSaga() {
    yield takeEvery(partialUpdate.type, runPartialUpdateObservations)
}

export default observationPartialUpdateSaga