import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { add, 
         addSucceded, 
         addFailed, 
         IReviewStatus } from "../../reducers/reviewStatusesSlice"
import { PayloadAction } from "@reduxjs/toolkit"


function* runCreateStatus(action: PayloadAction<IReviewStatus>) {
  try {
    const newStatus: IReviewStatus = yield call((data: IReviewStatus) =>
       backend.post(`reviewstatuses`, data), action.payload);
       
    yield put(addSucceded(newStatus));
  } catch (e) {
    yield put(addFailed(e));
  }
}

function* reviewStatusCreateSaga() {
  yield takeEvery(add.type, runCreateStatus)
}

export default reviewStatusCreateSaga