import { combineReducers, PayloadAction } from "@reduxjs/toolkit"
import reviewsSlice from "./reviewsSlice"
import observationsSlice from "./observationsSlice"
import departmentsSlise from "./departmentsSlice"
import reviewStatusesSlice from "./reviewStatusesSlice"
import classificationsSlice from "./classificationsSlice"
import statusesSlice from "./statusesSlice"
import systemsSlice from "./systemsSlice"
import siteSlice from "./siteSlice"
import reviewersSlice from "./reviewersSlice"

export type PayloadActionWithResolve<T> = {
  onResolve?: (data?: any) => void
} & PayloadAction<T>

const rootReducer = combineReducers({
  sites: siteSlice.reducer,
  observations: observationsSlice.reducer,
  reviews: reviewsSlice.reducer,
  departments: departmentsSlise.reducer, 
  classifications: classificationsSlice.reducer,
  reviewStatuses: reviewStatusesSlice.reducer,
  statuses: statusesSlice.reducer,
  systems: systemsSlice.reducer,
  reviewers: reviewersSlice.reducer
});

export type AppState = ReturnType<typeof rootReducer>

export default rootReducer
