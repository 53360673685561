import { PayloadAction } from "@reduxjs/toolkit";
import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { fetchReview, fetchReviewSucceeded, fetchReviewFailed, IReview } from "../../reducers/reviewsSlice"

function* runFetchSingleReview(action: PayloadAction<number>) {
  try {
    const review: IReview = yield call(() =>
       backend.get(`reviews/` + action.payload)
    )
    yield put(fetchReviewSucceeded(review));
  } 
  catch (e) {
    yield put(fetchReviewFailed(e))
  }
}

function* fetchSingleReviewSaga() {
  yield takeEvery(fetchReview.type, runFetchSingleReview)
}

export default fetchSingleReviewSaga