import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { AuthContext } from "./auth/AuthProvider";
import { Dialog, 
         CircularProgress, 
         Container, 
         Button } from '@material-ui/core';
 
const PrivateRoute = ({ component: RouteComponent, ...rest}) => {

    const {loadingAuthState, authenticationError, signIn} = useContext(AuthContext);

    if (loadingAuthState) {
        return (
            <Dialog open={true}>
                <Container style={{padding: '15px'}} >
                    <div style={{display: 'flex'}}>
                        <span><CircularProgress/></span>
                        <span style={{marginTop: '10px', marginLeft: '10px'}}>
                            Loading...
                        </span>
                    </div>
                </Container>
            </Dialog>
        );
    }

    if(authenticationError) {
        return (
            <Dialog open={true}>
                <Container style={{padding: '15px'}} >
                    <h5>Authenitication</h5>
                    <div>An error occured during authentication. 
                         Please try again.
                         If it doesn't work contact your administrator.
                    </div>
                    <Button variant="contained"
                            color="primary" 
                            onClick={() => signIn(false)}
                            size='small'
                            style={{float: 'right', marginTop: '10px'}}>
                        Sign in
                    </Button>
                </Container>
            </Dialog>
        );
    }

    return (
        <Route 
            {...rest}
            render={routeProps => 
                    // authenticated ? 
                        //(
                        <RouteComponent {...routeProps} />
                        //) : 
                        //(<Redirect to={{pathname: "/auth/login", state: {prevPath: rest.path}}} 
                    ///>
                    //)
            }
        />
    );
}

export default PrivateRoute