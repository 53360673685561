import React, { useContext } from 'react'
import { Button, Grid, TextField, Typography, Accordion, LinearProgress, Box, Checkbox, FormControlLabel } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import navigateTo from '../services/navigation'
import { useSelector, useDispatch } from "react-redux"
import { AppState } from "../reducers/rootReducer"
import {
    IReviewPatch,
    IReview,
    fetchReview,
    partialUpdateReview,
    resetMessage
} from "../reducers/reviewsSlice"
import { fetchDepartments } from "../reducers/departmentsSlice"
import { fetch as fetchReviewStatuses } from "../reducers/reviewStatusesSlice"
import { AppDispatch } from "../store"
import { useParams } from "react-router-dom"
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useSnackbar } from 'notistack'
import { withStyles } from '@material-ui/core/styles'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import { dayDifference } from '../utils/dateTimeUtils'
import ReviewStatusSelector from './ReviewStatusSelector'
import ReviewerSelector from './ReviewerSelector'
import Observations from './Observations'
import PrintIcon from '@material-ui/icons/Print';
import { useState } from 'react'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import DepartmentEdit from './DepartmentEdit'
import { AuthContext } from '../auth/AuthProvider'

const AccordionSummary = withStyles({
    content: {
        '&$expanded': {
            margin: '5px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const Review = () => {
    const param = useParams<any>();
    const observations = useSelector((state: AppState) => state.observations.items);
    const editReview = useSelector((state: AppState) => state.reviews.editItem);
    const isLoading = useSelector((state: AppState) => state.reviews.isLoading);
    const message = useSelector((state: AppState) => state.reviews.message);
    const isError = useSelector((state: AppState) => state.reviews.isError);
    const departments = useSelector((state: AppState) => state.departments.items);
    const isFetchingDepartments = useSelector((state: AppState) => state.departments.isLoading);
    const reviewStatuses = useSelector((state: AppState) => state.reviewStatuses);
    const isFetchingReviewStatuses = useSelector((state: AppState) => state.reviewStatuses.isLoading);
    const dispatch = useDispatch<AppDispatch>();
    const { enqueueSnackbar } = useSnackbar();
    const [expandedPanel, setExpandedPanel] = useState(true);
    const [dateReceivedByQaValid, setDateReceivedByQaValid] = useState(true);
    const [mfgStartDateValid, setMfgStartDateValid] = useState(true);
    const [dateRviewQaStartValid, setDateRviewQaStartValid] = useState(true);
    const [dateCommentsSentValid, setDateCommentsSentValid] = useState(true);
    const [dateAnswersReceivedValid, setDateAnswersReceivedValid] = useState(true);
    const [dateApporvedValid, setDateApporvedValid] = useState(true);
    const currentReviewId = parseInt(param.id, 10) || -1;
    const { isAdmin } = useContext(AuthContext);


    let review: IReview = {} as IReview;

    if (currentReviewId < 0) {
        return (<>Failed to get Review ID. Review ID should be supplied as a parameter.</>);
    }

    if (!isLoading) {
        if (editReview && editReview.id === currentReviewId) {
            review = editReview;
        } else {
            dispatch(fetchReview(currentReviewId));
            return (<>Loading...</>);
        }
    }

    if (departments == null &&
        !isFetchingDepartments &&
        !isError) {
        dispatch(fetchDepartments())
    }

    if (reviewStatuses == null &&
        !isFetchingReviewStatuses &&
        !isError) {
        dispatch(fetchReviewStatuses())
    }

    if (message) {
        enqueueSnackbar(message.message, { variant: message.variant });
        dispatch(resetMessage());
    }

    const autoSave = (fieldName: string, newValue: any) => {
        dispatch(partialUpdateReview({
            id: currentReviewId,
            patch: [{
                op: "replace",
                path: "/" + fieldName,
                value: newValue
            }]
        } as IReviewPatch));
    }

    const handlePrint = () => {
        try {
            let doc = new jsPDF({ format: 'letter' });
            doc.setFontSize(20);
            doc.setTextColor(80, 80, 80);
            doc.text("Batch Record Review Observations", 50, 15, { align: "left" });

            let labels = [
                "Description:",
                "Code:",
                "Lot:",
                "Manufacturing Date:",
                "Department:",
                "Reviewer QA:",
                "Date Comments Sent:",
            ];

            let data = [
                review.description ?? '',
                review.code ?? '',
                review.lot ?? '',
                review.mfgStartDate ? new Date(review.mfgStartDate).toLocaleDateString() : '',
                review.department ?? '',
                review.reviewerQa ?? '',
                review.dateCommentsSent ? new Date(review.dateCommentsSent).toLocaleDateString() : ''
            ];

            doc.setFontSize(10);
            doc.setFont("Helvetica", "bold");
            doc.text(labels, 45, 30, { align: "right", lineHeightFactor: 1.5 });
            doc.setFont("Helvetica", "normal");
            doc.text(data, 47, 30, { lineHeightFactor: 1.5 });

            if (observations && observations.length > 0) {

                let tableData: any[] = observations.map(row => [row.number, row.location, row.observation, row.system, row.classification, row.answer, row.status]);

                autoTable(
                    doc,
                    {
                        head: [['#', 'Location', 'Observation', 'System', 'Classification', 'Answer', 'Status']],
                        body: tableData,
                        columnStyles: {
                            0: { cellWidth: 10 },
                            1: { cellWidth: 20 },
                            2: { cellWidth: 55 },
                            3: { cellWidth: 20 },
                            4: { cellWidth: 27 },
                            5: { cellWidth: 55 },
                            6: { cellWidth: 20 },
                        },
                        startY: 70,
                        margin: { left: 5 }
                    },
                )
            }

            let pages = doc.getNumberOfPages();
            let pageWidth = doc.internal.pageSize.width;
            let pageHeight = doc.internal.pageSize.height;

            for (let j = 1; j < pages + 1; j++) {
                let horizontalPos = pageWidth / 2;
                let verticalPos = pageHeight - 10;
                doc.setPage(j);
                doc.text(`${j} of ${pages}`, horizontalPos, verticalPos, { align: 'center' });
            }

            doc.autoPrint({ variant: 'javascript', });
            window.open(doc.output('bloburl').toString(), '_blank');

        } catch (e) {
            if (typeof e === "string") {
                console.log("%c" + e, "color: red");
            } else if (e instanceof Error) {
                console.log("%c" + e.stack, "color: red");
            }

            enqueueSnackbar("Error on printing the review. Please contact your administrator.", { variant: 'error' });
        }
    }

    const dateRangeValid = (dateValue: string): boolean => {
        return Date.parse(dateValue) < Date.parse("01-12-2049") &&
            Date.parse(dateValue) > Date.parse("01-01-2010") ? true : false;
    }

    return (
        <React.Fragment>
            <Grid container >
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Button startIcon={<ArrowBackIosIcon />}
                        style={{ marginBottom: '10px' }}
                        onClick={() => {
                            navigateTo('/')
                        }}>Back
                    </Button>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                    <Button variant="outlined"
                        startIcon={<PrintIcon />}
                        style={{ float: 'right', marginBottom: '15px', marginRight: '5px' }}
                        disabled={isError || isLoading}
                        onClick={handlePrint}>Print</Button>
                </Grid>
            </Grid>
            <Box display={isLoading ? 'block' : 'none'}><LinearProgress /></Box>
            <Accordion id="review-accordion"
                defaultExpanded={expandedPanel}
                onChange={
                    (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
                        setExpandedPanel(isExpanded)
                    }}
                style={{ padding: '0px 10px', marginTop: '0px' }}>
                <AccordionSummary id="panel1a-header"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                >
                    <Grid container style={{ margin: '10px 0' }}>
                        <Grid item lg={1} md={2} sm={2} xs={12}>
                            <Typography variant="h6">Review</Typography>
                        </Grid>
                        <Grid item lg={8} md={7} sm={7} xs={12}>
                            <table style={{ float: 'left', visibility: expandedPanel ? 'collapse' : 'visible' }}>
                                <tbody>
                                    <tr>
                                        <td><Typography variant="subtitle2" style={{ float: 'right', lineHeight: 1 }} >Description:  </Typography></td>
                                        <td><Typography variant="body2" style={{ float: 'left' }}>{review.description}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography variant="subtitle2" style={{ float: 'right', lineHeight: 1 }} >Code:  </Typography></td>
                                        <td><Typography variant="body2" style={{ float: 'left' }}>{review.code}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography variant="subtitle2" style={{ float: 'right', lineHeight: 1 }} >Lot:  </Typography></td>
                                        <td><Typography variant="body2" style={{ float: 'left' }}>{review.lot}</Typography></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12}>
                            <table style={{ float: 'right' }}>
                                <tbody>
                                    <tr>
                                        <td><Typography variant="subtitle2" style={{ float: 'right', lineHeight: 1 }} >Entered by:  </Typography></td>
                                        <td><Typography variant="body2" style={{ float: 'left' }}>{review.modifiedBy}</Typography></td>
                                    </tr>
                                    <tr>
                                        <td><Typography variant="subtitle2" style={{ float: 'right', lineHeight: 1 }} >Date entered: </Typography></td>
                                        <td><Typography variant="body2" style={{ float: 'left' }}>{review.modifiedTimestamp ? new Date(review.modifiedTimestamp).toLocaleDateString() : ''}</Typography></td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <form>
                    <Grid container spacing={2} style={{ marginBottom: '10px' }}>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField label="Description"
                                name="description"
                                size="small"
                                fullWidth={true}
                                key={review.description}
                                defaultValue={review.description}
                                onBlur={(e) => {
                                    if (review.description !== e.target.value) {
                                        autoSave("description", e.target.value);
                                    }
                                }}
                                disabled={isLoading} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TextField label="Code"
                                name="code"
                                size="small"
                                fullWidth={true}
                                key={review.code}
                                defaultValue={review.code}
                                onBlur={(e) => {
                                    if (review.code !== e.target.value) {
                                        autoSave("code", e.target.value);
                                    }
                                }}
                                disabled={isLoading} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TextField label="Lot"
                                name="lot"
                                size="small"
                                fullWidth={true}
                                key={review.lot}
                                defaultValue={review.lot}
                                onBlur={(e) => {
                                    if (review.lot !== e.target.value) {
                                        autoSave("lot", e.target.value);
                                    }
                                }}
                                disabled={isLoading} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TextField label="Bulk Lot"
                                name="bulkLot"
                                size="small"
                                fullWidth={true}
                                key={review.bulkLot}
                                defaultValue={review.bulkLot}
                                onBlur={(e) => {
                                    if (review.bulkLot !== e.target.value) {
                                        autoSave("bulkLot", e.target.value);
                                    }
                                }}
                                disabled={isLoading} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td style={{ width: '100%' }}>
                                            <Autocomplete id="department"
                                                disableClearable
                                                options={departments?.map((option) => option.name) || []}
                                                defaultValue={review.department}
                                                key={review.department}
                                                onChange={(event: any, newValue: any, reason: any) => {
                                                    if (reason !== 'clear' && review.department !== newValue) {
                                                        autoSave("department", newValue);
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField {...params}
                                                        label="Department"
                                                        size="small"
                                                        disabled={isLoading} />
                                                )} />

                                        </td>
                                        <td>
                                            {isAdmin ? <DepartmentEdit /> : null}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TextField label="Manufacturing Date"
                                name="mfgStartDate"
                                size="small"
                                fullWidth={true}
                                type="date"
                                key={review.mfgStartDate ? review.mfgStartDate.toString().split('T')[0] : ''}
                                defaultValue={review.mfgStartDate ? review.mfgStartDate.toString().split('T')[0] : ''}
                                onBlur={(e) => {
                                    if (!review.mfgStartDate || (review.mfgStartDate.toString().split('T')[0] !== e.target.value)) {
                                        if (dateRangeValid(e.target.value)) {
                                            autoSave("mfgStartDate", e.target.value);
                                            setMfgStartDateValid(true);
                                        } else {
                                            setMfgStartDateValid(false);
                                        }
                                    }
                                }}
                                disabled={isLoading}
                                error={!mfgStartDateValid}
                                helperText={mfgStartDateValid ? "" : "Date out of range (2010-2050)"}
                                InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TextField label="Date - Received By QA"
                                name="dateReceivedByQa"
                                size="small"
                                fullWidth={true}
                                type="date"
                                key={review.dateReceivedByQa ? review.dateReceivedByQa.toString().split('T')[0] : ''}
                                defaultValue={review.dateReceivedByQa ? review.dateReceivedByQa.toString().split('T')[0] : ''}
                                onBlur={(e) => {
                                    if (!review.dateReceivedByQa || (review.dateReceivedByQa.toString().split('T')[0] !== e.target.value)) {
                                        if (dateRangeValid(e.target.value)) {
                                            autoSave("dateReceivedByQa", e.target.value);
                                            setDateReceivedByQaValid(true);
                                        } else {
                                            setDateReceivedByQaValid(false);
                                        }
                                    }
                                }}
                                disabled={isLoading}
                                error={!dateReceivedByQaValid}
                                helperText={dateReceivedByQaValid ? "" : "Date out of range (2010-2050)"}
                                InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TextField label="Date - Review QA Start"
                                name="dateRviewQaStart"
                                size="small"
                                fullWidth={true}
                                type="date"
                                key={review.dateRviewQaStart ? review.dateRviewQaStart.toString().split('T')[0] : ''}
                                defaultValue={review.dateRviewQaStart ? review.dateRviewQaStart.toString().split('T')[0] : ''}
                                onBlur={(e) => {
                                    if (!review.dateRviewQaStart || (review.dateRviewQaStart.toString().split('T')[0] !== e.target.value)) {
                                        if (dateRangeValid(e.target.value)) {
                                            autoSave("dateRviewQaStart", e.target.value);
                                            setDateRviewQaStartValid(true);
                                        } else {
                                            setDateRviewQaStartValid(false);
                                        }
                                    }
                                }}
                                disabled={isLoading}
                                error={!dateRviewQaStartValid}
                                helperText={dateRviewQaStartValid ? "" : "Date out of range (2010-2050)"}
                                InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TextField label="QA Review Time"
                                name="timeQaReview"
                                size="small"
                                variant='filled'
                                fullWidth={false}
                                value={dayDifference(review.dateCommentsSent, review.dateReceivedByQa) + ' day(s)'}
                                InputProps={{ readOnly: true }}
                                disabled={isLoading} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TextField label="Date Comments Sent"
                                name="dateCommentsSent"
                                size="small"
                                fullWidth={true}
                                type="date"
                                key={review.dateCommentsSent ? review.dateCommentsSent.toString().split('T')[0] : ''}
                                defaultValue={review.dateCommentsSent ? review.dateCommentsSent.toString().split('T')[0] : ''}
                                onBlur={(e) => {
                                    if (!review.dateCommentsSent || (review.dateCommentsSent.toString().split('T')[0] !== e.target.value)) {
                                        if (dateRangeValid(e.target.value)) {
                                            autoSave("dateCommentsSent", e.target.value);
                                            setDateCommentsSentValid(true);
                                        } else {
                                            setDateCommentsSentValid(false);
                                        }
                                    }
                                }}
                                disabled={isLoading}
                                error={!dateCommentsSentValid}
                                helperText={dateCommentsSentValid ? "" : "Date out of range (2010-2050)"}
                                InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TextField label="Date Answers Received"
                                name="dateAnswersReceived"
                                size="small"
                                fullWidth={true}
                                type="date"
                                key={review.dateAnswersReceived ? review.dateAnswersReceived.toString().split('T')[0] : ''}
                                defaultValue={review.dateAnswersReceived ? review.dateAnswersReceived.toString().split('T')[0] : ''}
                                onBlur={(e) => {
                                    if (!review.dateAnswersReceived || (review.dateAnswersReceived.toString().split('T')[0] !== e.target.value)) {
                                        if (dateRangeValid(e.target.value)) {
                                            autoSave("dateAnswersReceived", e.target.value);
                                            setDateAnswersReceivedValid(true);
                                        } else {
                                            setDateAnswersReceivedValid(false);
                                        }
                                    }
                                }}
                                disabled={isLoading}
                                error={!dateAnswersReceivedValid}
                                helperText={dateAnswersReceivedValid ? "" : "Date out of range (2010-2050)"}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TextField label="Date Approved"
                                name="dateApporved"
                                size="small"
                                fullWidth={true}
                                type="date"
                                key={review.dateApporved ? review.dateApporved.toString().split('T')[0] : ''}
                                defaultValue={review.dateApporved ? review.dateApporved.toString().split('T')[0] : ''}
                                onBlur={(e) => {
                                    if (!review.dateApporved || (review.dateApporved.toString().split('T')[0] !== e.target.value)) {
                                        if (dateRangeValid(e.target.value)) {
                                            autoSave("dateApporved", e.target.value);
                                            setDateApporvedValid(true);
                                        } else {
                                            setDateApporvedValid(false);
                                        }
                                    }
                                }}
                                disabled={isLoading}
                                error={!dateApporvedValid}
                                helperText={dateApporvedValid ? "" : "Date out of range (2010-2050)"}
                                InputLabelProps={{
                                    shrink: true,
                                }} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <TextField label="Prod Answer Time"
                                name="timeProdAnswer"
                                size="small"
                                variant='filled'
                                fullWidth={false}
                                value={dayDifference(review.dateAnswersReceived, review.dateCommentsSent) + " day(s)"}
                                disabled={isLoading} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <ReviewerSelector type="prod"
                                defaultValue={review.reviewerProd}
                                onNewValue={(newValue) => {
                                    autoSave("reviewerProd", newValue);
                                }} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12}>
                            <ReviewerSelector type="qa"
                                defaultValue={review.reviewerQa}
                                onNewValue={(newValue) => {
                                    autoSave("reviewerQa", newValue);
                                }} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12}>
                            <ReviewStatusSelector defaultValue={review.status}
                                onNewValue={(newValue) => {
                                    autoSave("status", newValue)
                                }} />
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={12}>
                            <FormControlLabel label="RFT"
                                control={
                                    <Checkbox
                                        color="primary"
                                        checked={!!review.isRFT}
                                        onChange={(event) => {
                                            autoSave("isRFT", event.target.checked)
                                        }}
                                        disabled={isLoading} />
                                } />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                            <TextField label="Comments"
                                name="comments"
                                size="small"
                                fullWidth={true}
                                key={review.comments}
                                defaultValue={review.comments}
                                onBlur={(e) => {
                                    if (review.comments !== e.target.value) {
                                        autoSave("comments", e.target.value);
                                    }
                                }}
                                disabled={isLoading} />
                        </Grid>
                    </Grid>
                </form>
            </Accordion>
            <Observations reviewId={currentReviewId} />
        </React.Fragment>
    );
}

export default Review;