import backend from "../../config/axios"
import { takeEvery, call, put } from "redux-saga/effects"
import { ISystem, 
         fetch, 
         fetchSucceded, 
         fetchFailed} from "../../reducers/systemsSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runFetchSystems(action: PayloadAction<ISystem>) {
    try {
        const systems: Array<ISystem> = yield call(() => backend.get(`observations/systems`));
        yield put(fetchSucceded(systems));
    } catch (e) {
        yield put(fetchFailed(e));
    }
}

function* systemsFetchSaga() {
    yield takeEvery(fetch.type, runFetchSystems)
}

export default systemsFetchSaga