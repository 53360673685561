import React from 'react'
import Review from '../components/Review'

const ReviewEditPage = () => {
  return (
    <React.Fragment>
        <Review />
    </React.Fragment>
  );
}

export default ReviewEditPage