import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import {
  IDepartment,
  remove,
  removeSucceded,
  removeFailed
} from "../../reducers/departmentsSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runDeleteDepartment(action: PayloadAction<IDepartment>) {
  try {
    yield call(() =>
      backend.delete(`departments/` + action.payload.id)
    )
    yield put(removeSucceded(action.payload.id ?? 0));
  } catch (e) {
    yield put(removeFailed(e));
    console.error("Error while removing department", e);

  }
}

function* departmentsDeleteSaga() {
  yield takeEvery(remove.type, runDeleteDepartment)
}

export default departmentsDeleteSaga