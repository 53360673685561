import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { 
    IReviewer, 
    remove, 
    removeSucceded, 
    removeFailed } from "../../reducers/reviewersSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runDeleteReviewer(action: PayloadAction<IReviewer>) {
  try {
    yield call(() =>
       backend.delete(`reviewers/` + action.payload.id)
    )
    yield put(removeSucceded(action.payload.id ?? 0));
  } catch (e) {
    yield put(removeFailed(e));
  }
}

function* reviewersDeleteSaga() {
  yield takeEvery(remove.type, runDeleteReviewer)
}

export default reviewersDeleteSaga