import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface ISite {
    id: number
    name: string
}

export interface ISiteState {
    items: Array<ISite>,
    selectedSite?: ISite
}

const initialState: ISiteState = {
    items: [
        {  name: 'Candiac', id: 1 },
        {  name: 'Royalmount', id: 2 }
    ],
    selectedSite: undefined,
}

const siteSlice = createSlice({
    name: "site",
    initialState,
    reducers: {
        setSite: (state: ISiteState, action: PayloadAction<ISite>) => {
            state.selectedSite = action.payload;
        }
    },
})

export const { 
    setSite } = siteSlice.actions

export default siteSlice