import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { INotificationMessage } from "../services/notification"

export interface IStatus {
    id: number
    name: string
}

export interface IStatusesState {
    items: Array<IStatus> | undefined
    isLoading: boolean,
    isError: boolean,
    message: INotificationMessage | undefined
}

const initialState: IStatusesState = {
    items: undefined,
    isLoading: false,
    isError: false,
    message: undefined
}

const statusesSlice = createSlice({
    name: "status",
    initialState,
    reducers: {
        fetch: (state: IStatusesState) => {
            state.message = undefined;
            state.isLoading = true;
            state.isError = false;
        },
        fetchSucceded: (state: IStatusesState, action: PayloadAction<Array<IStatus>>) => {
            state.items = action.payload;
            state.isLoading = false;
            state.message = undefined;
            state.isError = false;
        },
        fetchFailed: (state: IStatusesState, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.message = { message: 'Error on getting observation statuses', variant: "error" };
            state.isError = true;
        },
        add: (state: IStatusesState, action: PayloadAction<IStatus>) => {
            state.isError = false;
            state.isLoading = true;
        },
        addSucceded: (state: IStatusesState, action: PayloadAction<IStatus>) => {  
            state.isLoading = false;
            state.items = [action.payload].concat(state.items ?? []).sort(function (a, b) { return (a.id ?? 0) - (b.id ?? 0) });
            state.isError = false;
        },
        addFailed: (state: IStatusesState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.isError = true;
            state.message = {message: 'Error on adding new status', variant: 'error'}
        },
        update: (state: IStatusesState, action: PayloadAction<IStatus>) => {
            state.isError = false;
        },
        updateSucceded: (state: IStatusesState, action: PayloadAction<IStatus>) => {  
            state.isLoading = false;
            if(state.items) {
                let updateIndex = state.items?.findIndex(x => x.id === action.payload.id);
                if(updateIndex >= 0) {
                    state.items[updateIndex] = action.payload;
                }
            }

            state.isError = false;
        },
        updateFailed: (state: IStatusesState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.isError = true;
            state.message = {message: 'Failed to update status', variant: 'error'}
        },
        remove: (state: IStatusesState, action: PayloadAction<IStatus>) => {
            state.isError = false;
        },
        removeSucceded: (state: IStatusesState, action: PayloadAction<number>) => {  
            state.isLoading = false;
            if(state.items) {
                state.items.splice(state.items.findIndex(x => x.id === action.payload), 1);
            }
            state.isError = false;
        },
        removeFailed: (state: IStatusesState, action: PayloadAction<any> ) => {
            state.isLoading = false;
            state.isError = true;
            state.message = {message: 'Failed to update status', variant: 'error'}
        },
    },
})

export const { 
    fetch,
    fetchSucceded,
    fetchFailed,
    add, 
    addSucceded, 
    addFailed,
    update, 
    updateSucceded, 
    updateFailed,
    remove, 
    removeSucceded, 
    removeFailed,  
} = statusesSlice.actions

export default statusesSlice