import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { add, 
         addSucceded, 
         addFailed, 
         IReviewer } from "../../reducers/reviewersSlice"
import { PayloadAction } from "@reduxjs/toolkit"


function* runCreateReview(action: PayloadAction<IReviewer>) {
  try {
    const newReviewer: IReviewer = yield call((data: IReviewer) =>
       backend.post(`reviewers`, data), action.payload);
       
    yield put(addSucceded(newReviewer));
  } catch (e) {
    yield put(addFailed(e));
  }
}

function* reviewerCreateSaga() {
  yield takeEvery(add.type, runCreateReview)
}

export default reviewerCreateSaga