import * as React from 'react';
import NavMenu from './NavMenu';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <NavMenu />
        <div className="main-container" >
            {props.children}
        </div>
        {/* <div id="footer">Pharmascience Inc. All rights reserved 2021</div> */}
    </React.Fragment>
);
