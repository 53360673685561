import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { 
    IClassification, 
    remove, 
    removeSucceded, 
    removeFailed } from "../../reducers/classificationsSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runDeleteClassification(action: PayloadAction<IClassification>) {
  try {
    yield call(() =>
       backend.delete(`observations/classifications/` + action.payload.id)
    )
    yield put(removeSucceded(action.payload.id ?? 0));
  } catch (e) {
    yield put(removeFailed(e));
  }
}

function* classificationsDeleteSaga() {
  yield takeEvery(remove.type, runDeleteClassification)
}

export default classificationsDeleteSaga