import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import {
  deleteReview,
  deleteReviewSucceded,
  deleteReviewFailed
} from "../../reducers/reviewsSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runDeleteReview(action: PayloadAction<number>) {
  try {
    yield call(() =>
      backend.delete(`reviews/` + action.payload)
    )
    yield put(deleteReviewSucceded(action.payload ?? 0));
  } catch (e) {
    yield put(deleteReviewFailed(e));
  }
}

function* deleteReviewSaga() {
  yield takeEvery(deleteReview.type, runDeleteReview)
}

export default deleteReviewSaga