import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { 
    remove, 
    removeSucceded, 
    removeFailed } from "../../reducers/observationsSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runDeleteObservation(action: PayloadAction<number>) {
  try {
    yield call(() =>
       backend.delete(`observations/` + action.payload)
    )
    yield put(removeSucceded(action.payload ?? 0));
  } catch (e) {
    yield put(removeFailed(e));
  }
}

function* observationsDeleteSaga() {
  yield takeEvery(remove.type, runDeleteObservation)
}

export default observationsDeleteSaga