import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import {
  IDepartment,
  update,
  updateSucceded,
  updateFailed
} from "../../reducers/departmentsSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runUpdateDepartment(action: PayloadAction<IDepartment>) {
  try {
    const updatedDepartment: IDepartment = yield call((data: IDepartment) =>
      backend.put(`departments/` + data.id, data), action.payload
    )
    yield put(updateSucceded(updatedDepartment));
  } catch (e) {
    yield put(updateFailed(e));
    console.error("Error while updating department", e);
  }
}

function* departmentsUpdateSaga() {
  yield takeEvery(update.type, runUpdateDepartment)
}

export default departmentsUpdateSaga