import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { 
  partialUpdateReview, 
  partialUpdateReviewSucceded, 
  partialUpdateReviewFailed, 
  IReviewPatch, 
  IReview } from "../../reducers/reviewsSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runPartialUpdateReviews(action: PayloadAction<IReviewPatch>) {
  try {
    const review: IReview = yield call((data: IReviewPatch) =>
      backend.patch(`reviews/` + data.id, data.patch), action.payload
    )
    yield put(partialUpdateReviewSucceded(review));
  } catch (e) {
    yield put(partialUpdateReviewFailed(e));
  }
}

function* partialUpdateReviewSaga() {
  yield takeEvery(partialUpdateReview.type, runPartialUpdateReviews)
}

export default partialUpdateReviewSaga