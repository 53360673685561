import { takeEvery, call, put } from "redux-saga/effects"
import backend from "../../config/axios"
import { 
    ISystem, 
    remove, 
    removeSucceded, 
    removeFailed } from "../../reducers/systemsSlice"
import { PayloadAction } from "@reduxjs/toolkit"

function* runDeleteSystem(action: PayloadAction<ISystem>) {
  try {
    yield call(() =>
       backend.delete(`observations/systems/` + action.payload.id)
    )
    yield put(removeSucceded(action.payload.id ?? 0));
  } catch (e) {
    yield put(removeFailed(e));
  }
}

function* systemsDeleteSaga() {
  yield takeEvery(remove.type, runDeleteSystem)
}

export default systemsDeleteSaga