import React, { Suspense, lazy } from 'react';
import Layout from './components/Layout';
import { Switch, Route } from "react-router-dom"
import ReviewEditPage from './pages/ReviewEditPage';
import ImportDataPage from './pages/ImportDataPage';
import PrivateRoute from './PrivateRoute';
import {
    Dialog,
    CircularProgress,
    Container
} from '@material-ui/core';
import './custom.css'

const HomePage = lazy(() => import("./pages/HomePage"));
//const antIcon = < CircularProgress style={{ fontSize: 24 }} />;

const App = () => {
    return (
        <Suspense
            fallback={
                <Dialog open={true}>
                    <Container style={{ padding: '15px' }} >
                        <div style={{ display: 'flex' }}>
                            <span><CircularProgress /></span>
                            <span style={{ marginTop: '10px', marginLeft: '10px' }}>
                                Loading...
                            </span>
                        </div>
                    </Container>
                </Dialog>
            }>
            <Layout>
                <Switch>
                    <PrivateRoute exact path="/" component={HomePage} />
                    <Route exact path="/review/:id" component={ReviewEditPage} />
                    <Route exact path="/importData" component={ImportDataPage} />
                </Switch>
            </Layout>
        </Suspense>
    );
};

export default App;