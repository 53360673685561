import React from 'react'
import { TextField, IconButton } from '@material-ui/core'
import { fetch as fetchSystems } from "../reducers/systemsSlice"
import { fetch as fetchClassifications } from "../reducers/classificationsSlice"
import { fetch as fetchStatuses } from "../reducers/statusesSlice"
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSelector, useDispatch } from "react-redux"
import { AppState } from "../reducers/rootReducer"
import { AppDispatch } from "../store"
import { IObservation, IObservationPatch, partialUpdate, remove } from "../reducers/observationsSlice"
import { ISystemsState } from "../reducers/systemsSlice"
import TableRow from '@material-ui/core/TableRow'
import MuiTableCell from "@material-ui/core/TableCell"
import { withStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete';

const TableCell = withStyles({
    root: {
        borderBottom: "none",
        paddingTop: 10,
        paddingRight: 5,
        paddingBottom: 10,
        paddingLeft: 5,
        "&:last-child": {
            paddingRight: 0
        },
        verticalAlign: 'bottom',
        "&:first-child": {
            paddingTop: 15,
        }
    }
})(MuiTableCell);

type Props = {
    value?: IObservation,
    isSuperAdmin?: boolean,
    onNewValue?: (newValue: string) => void
}

const ObservationItem = ({ value, isSuperAdmin: isSuperAdmin, onNewValue }: Props) => {

    const isLoading = useSelector((state: AppState) => state.observations.isLoading);
    const systems = useSelector((state: AppState) => state.systems);
    const classifications = useSelector((state: AppState) => state.classifications);
    const statuses = useSelector((state: AppState) => state.statuses);
    const dispatch = useDispatch<AppDispatch>();

    if (!systems.items &&
        !systems.isLoading &&
        !systems.isError) {
        dispatch(fetchSystems());
    }

    if (!classifications.items &&
        !classifications.isLoading &&
        !classifications.isError) {
        dispatch(fetchClassifications());
    }

    if (!statuses.items &&
        !statuses.isLoading &&
        !statuses.isError) {
        dispatch(fetchStatuses());
    }

    const autoSave = (fieldName: string, newValue: any) => {
        dispatch(partialUpdate({
            id: value?.id,
            patch: [{
                op: "replace",
                path: "/" + fieldName,
                value: newValue
            }]
        } as IObservationPatch));
    }

    const handleDelete = () => {
        if (value) {
            dispatch(remove(value?.id));
        }
    }

    const isSystemValid = (): boolean => {
        return value && value.system && value.system.length > 0 ? true : false;
    }

    const isClassificationValid = (): boolean => {
        return value && value.classification && value.classification.length > 0 ? true : false;
    }

    return (
        <React.Fragment>
            <TableRow key={value?.id}>
                <TableCell>{value?.number}</TableCell>
                <TableCell>
                    <TextField
                        name="location"
                        size="small"
                        fullWidth={true}
                        key={value?.location}
                        defaultValue={value?.location}
                        onBlur={(e) => {
                            if (value?.location !== e.target.value) {
                                autoSave("location", e.target.value);
                            }
                        }}
                        disabled={isLoading}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        name="observation"
                        size="small"
                        multiline={true}
                        fullWidth={true}
                        key={value?.observation}
                        defaultValue={value?.observation}
                        onBlur={(e) => {
                            if (value?.observation !== e.target.value) {
                                autoSave("observation", e.target.value);
                            }
                        }}
                        disabled={isLoading}
                    />
                </TableCell>
                <TableCell>
                    <Autocomplete
                        id="system"
                        disableClearable
                        options={systems.items?.map((option) => option.name) || []}
                        defaultValue={value?.system}
                        onChange={(event: any, newValue: any, reason: any) => {
                            if (value?.system !== newValue) {
                                autoSave("system", newValue);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                error={!isSystemValid()}
                                label={isSystemValid() ? "" : "System is required"}
                                InputLabelProps={{ shrink: true }}
                                disabled={isLoading} />
                        )} />
                </TableCell>
                <TableCell>
                    <Autocomplete
                        id="classification"
                        disableClearable
                        options={classifications.items?.map((option) => option.name) || []}
                        defaultValue={value?.classification}
                        onChange={(event: any, newValue: any, reason: any) => {
                            if (value?.classification !== newValue) {
                                autoSave("classification", newValue);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                error={!isClassificationValid()}
                                label={isClassificationValid() ? "" : "Classification is required"}
                                InputLabelProps={{ shrink: true }}
                                disabled={isLoading} />
                        )} />
                </TableCell>
                <TableCell>
                    <TextField
                        name="answer"
                        size="small"
                        multiline={true}
                        fullWidth={true}
                        key={value?.answer}
                        defaultValue={value?.answer}
                        onBlur={(e) => {
                            if (value?.answer !== e.target.value) {
                                autoSave("answer", e.target.value);
                            }
                        }}
                        disabled={isLoading}
                    />
                </TableCell>
                <TableCell>
                    <Autocomplete
                        id="status"
                        disableClearable
                        options={statuses.items?.map((option) => option.name) || []}
                        defaultValue={value?.status}
                        onChange={(event: any, newValue: any, reason: any) => {
                            if (value?.classification !== newValue) {
                                autoSave("status", newValue);
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                size="small"
                                disabled={isLoading} />
                        )} />
                </TableCell>
                {isSuperAdmin ?
                    <TableCell style={{ width: "30px" }}>
                        <IconButton style={{ color: "black", padding: 5, float: "right" }}
                            size="medium"
                            onClick={handleDelete}
                            disabled={isLoading}>
                            <DeleteIcon />
                        </IconButton>
                    </TableCell> : null}
            </TableRow>
        </React.Fragment>
    );
};

export default ObservationItem;